import ProtectedPage from '../../layouts/ProtectedPage';
import WarehouseLocationList from '../../components/warehouseLocation/WarehouseLocationList';

const WarehouseLocationPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Warehouses`,
      }}
    >
      {(headerProps) => <WarehouseLocationList headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default WarehouseLocationPage;
