import {
  default as Origin,
  Cell as OriginCell,
  Header as OriginHeader,
  Headers as OriginHeaders,
  Rows as OriginRows,
  Row as OriginRow,
} from '@atlaskit/table-tree';

export const Cell = OriginCell;
export const Header = OriginHeader;
export const Headers = OriginHeaders;
export const Rows = OriginRows;
export const Row = OriginRow;

export default Origin;
